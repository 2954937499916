.navbarH1 {
    margin-left: 5px;
}
.navbarCss {
    width: 97%;
}
.myFooter {
      width: 100%;
      height: 95%
}

@media only screen and (max-width: 568px) {
    .navbarCss {
        width: 100vw;
    }

}